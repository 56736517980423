<template>
    <router-link class="submenu-item py-2 px-3 mb-2 rounded-md cursor-pointer hover:bg-gray-100 hover:bg-opacity-20 transition" :to="to">
        <slot />
    </router-link>
</template>
<script>
export default {
    props: {
        to: {
            type: String,
            default: "",
        },
    },
};
</script>
<style>
.collapsed .submenu-item {
    color: #333;
    white-space: nowrap;
}
</style>
